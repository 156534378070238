import { useField } from 'formik'
import { FormInvalidFeedback } from '../FormInvalidFeedback'
import { CFormInput } from '@coreui/react-pro'
import { useState, useEffect, useRef } from 'react'

export const SearchableSelect = ({ options, ...props }) => {
  const [field, meta] = useField(props)
  const [search, setSearch] = useState('')
  const [isDropDownVisible, setDropdownVisible] = useState(false)
  const ref = useRef(null)
  const isValid = !!(meta.touched && meta.error)
  const feedbackMsg = isValid ? meta.error : ''

  const filteredOptions = options
    ?.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.label.localeCompare(b.label))

  const getSelectedOption = (value) => {
    if (!value) {
      return null
    }

    return options?.find((option) => option.value === value)
  }

  useEffect(() => {
    if (isDropDownVisible === false) {
      const selectedOption = getSelectedOption(field.value)
      setSearch(selectedOption ? selectedOption.label : '')
    }
  }, [field.value, options, isDropDownVisible])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownVisible(false)
    }
  }

  const handleOptionClick = (value) => {
    const selectedOption = getSelectedOption(value)
    setSearch(selectedOption ? selectedOption.label : '')
    if (props?.onChange) {
      props.onChange({ target: { name: field.name, value: selectedOption.label } })
    } else {
      field.onChange({ target: { name: field.name, value } })
    }
    setDropdownVisible(false)
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
    setDropdownVisible(true)
  }

  return (
    <div className="position-relative mb-3" ref={ref}>
      <CFormInput
        {...field}
        value={search}
        onChange={handleChange}
        onFocus={() => setDropdownVisible(true)}
        floatingLabel={props.placeholder}
        valid={meta.touched && !isValid}
        {...props}
      />

      {isDropDownVisible && (
        <div className="dropdown-menu show w-100 searchable-dropdown">
          {filteredOptions?.map((option) => (
            <div
              key={option.value}
              className="dropdown-item cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                handleOptionClick(option.value)
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}

      {isValid && <FormInvalidFeedback feedbackMsg={feedbackMsg} />}
    </div>
  )
}
