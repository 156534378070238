import { FieldArray, useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormTextInput } from '../FormTextInput'
import { FormTextarea } from '../FormTextarea'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormContactType } from './FormContactType'
import FormSelectCustomerPartner from './FormSelectCustomerPartner'
import FormSelectCustomerBranch from './FormSelectCustomerBranch'
import { AuthContext, ROLE } from 'src/contexts/authContext'

export const contactValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  contactType: 'private',
  description: '',
}

export default function FormSelectMultipleContacts({
  readOnly,
  setInitialvaluesChanged,
  partners,
  branches,
  partnerSelectionDisabled,
  editMode,
}) {
  const { t } = useTranslation()
  const {
    values: { contacts },
  } = useFormikContext()

  const { role } = useContext(AuthContext)
  const isEditAble = role !== ROLE.MANUFACTURER_ADMIN || !editMode

  return (
    <>
      <FieldArray
        name="contacts"
        render={({ push, remove }) => (
          <>
            {contacts.map((contact, index) => (
              <div
                key={index}
                className={`${contacts.length - 1 !== index ? 'mb-5' : ''} position-relative`}
              >
                <p className="spartan-font">
                  {contacts.length === 1
                    ? t('Backoffice.Wizard.AddProject.steps.third')
                    : `${t('Backoffice.Wizard.AddProject.steps.third')} (${index + 1})`}
                </p>
                {(contacts.length > 1 && !isEditAble) ||
                  (!readOnly && (
                    <FormDeleteFieldButton
                      index={index}
                      handleClick={() => {
                        remove(index)
                        setInitialvaluesChanged(true)
                      }}
                    />
                  ))}
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.firstName')}
                  type="text"
                  id={`contacts.${index}.firstName`}
                  name={`contacts.${index}.firstName`}
                  disabled={!isEditAble || readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.lastName')}
                  type="text"
                  id={`contacts.${index}.lastName`}
                  name={`contacts.${index}.lastName`}
                  disabled={!isEditAble || readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.mainContactEmail')}
                  type="email"
                  id={`contacts.${index}.email`}
                  name={`contacts.${index}.email`}
                  disabled={!isEditAble || readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.phoneNumber')}
                  type="text"
                  id={`contacts.${index}.phoneNumber`}
                  name={`contacts.${index}.phoneNumber`}
                  disabled={!isEditAble || readOnly}
                />
                <FormContactType
                  name={`contacts.${index}.contactType`}
                  readOnly={!isEditAble || readOnly}
                />
                {contact.contactType === 'private' && (
                  <>
                    {partners.length ? (
                      <FormSelectCustomerPartner
                        partnerOptions={partners}
                        readOnly={!isEditAble || readOnly || partnerSelectionDisabled}
                        allBranches={branches}
                        contactIndex={index}
                      />
                    ) : (
                      <FormSelectCustomerBranch
                        readOnly={!isEditAble || readOnly || partnerSelectionDisabled}
                        allBranches={branches}
                        contactIndex={index}
                      />
                    )}
                  </>
                )}

                <FormTextarea
                  placeholder={t('Backoffice.Wizard.AddProject.Label.description')}
                  type="text"
                  id={`contacts.${index}.description`}
                  name={`contacts.${index}.description`}
                  disabled={!isEditAble || readOnly}
                />
              </div>
            ))}
            {!isEditAble ||
              (!readOnly && <FormAddAnotherButton push={push} values={contactValues} />)}
          </>
        )}
      />
    </>
  )
}
