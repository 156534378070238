import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import EventEmitter from 'mitt'

export const GlobalVariablesContext = React.createContext({})
export const GlobalVariablesContextProvider = GlobalVariablesContext.Provider
export const errorEventEmitter = new EventEmitter()
import { getHolidays } from '../services/requests/calendar'
import { getCategoryMetaTags } from 'src/services/requests/strapi/categoryMetaTags'
import { addYears } from 'src/views/pages/backoffice/lib/backoffice-lib'

const GlobalVariablesContextWrapper = (props) => {
  // const [locale, setLocale] = useState('en')
  const [pathIsVideoConference, setPathIsVideoConference] = useState(false)
  const [startedVideoConference, setStartedVideoConference] = useState(false)
  const [loginModalVisible, setLoginModalVisible] = useState(false)
  const [registerModalVisible, setRegisterModalVisible] = useState(false)
  const [requestCodeModalVisible, setRequestCodeModalVisible] = useState(false)
  const [validateCodeModalVisible, setValidateCodeModalVisible] = useState(false)
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)
  const [verifyCodeModalVisible, setVerifyCodeModalVisible] = useState(false)
  const [domainNotRegisteredModalVisible, setDomainNotRegisteredModalVisible] = useState(false)
  const [newPasswordModalVisible, setNewPasswordModalVisible] = useState(false)
  const [registrationSuccessModalVisible, setRegistrationSuccessModalVisible] = useState(false)
  const [forceChangePasswordModalVisible, setForceChangePasswordModalVisible] = useState(false)
  const [forceUsername, setForceUsername] = useState('')
  const [forcePassword, setForcePassword] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const urlSearchParams = new URLSearchParams(window.location.search)
  const type = urlSearchParams.get('type') ?? 'buyer'
  const [tab, setTab] = useState(type === 'seller' ? 'Vendor' : 'Buyer')
  const [error, setError] = useState()
  const [holidays, setHolidays] = useState([])
  const [metaTags, setMetaTags] = useState(null)
  const { i18n } = useTranslation()

  useEffect(() => {
    //do not remove this console.log
    error ? console.log(error) : ''
  }, [error])

  useEffect(() => {
    const showError = () => {
      let array = window.localStorage.getItem('errors')
      array = array ? JSON.parse(array) : []
      let error = array.pop()
      window.localStorage.setItem('errors', JSON.stringify(array))
      setError(error)
      //clearing the error message after 3 sconds to avoid multiple error renders
      setTimeout(() => {
        setError(null)
      }, 3000)
    }

    getHolidays()
      .then((res) => {
        setHolidays(addYears(res.data) || res.data)
      })
      .catch((err) => {
        console.log(err)
      })

    errorEventEmitter.on('error', showError)

    return () => {
      errorEventEmitter.off('error', showError)
    }
  }, [])

  useEffect(() => {
    getCategoryMetaTags(i18n.language).then((response) => {
      setMetaTags(response)
    })
  }, [i18n.language])

  return (
    <>
      <GlobalVariablesContextProvider
        value={{
          // locale,
          // setLocale,
          pathIsVideoConference,
          setPathIsVideoConference,
          startedVideoConference,
          setStartedVideoConference,
          loginModalVisible,
          setLoginModalVisible,
          registerModalVisible,
          setRegisterModalVisible,
          requestCodeModalVisible,
          setRequestCodeModalVisible,
          validateCodeModalVisible,
          setValidateCodeModalVisible,
          changePasswordModalVisible,
          setChangePasswordModalVisible,
          verifyCodeModalVisible,
          setVerifyCodeModalVisible,
          domainNotRegisteredModalVisible,
          setDomainNotRegisteredModalVisible,
          newPasswordModalVisible,
          setNewPasswordModalVisible,
          registrationSuccessModalVisible,
          setRegistrationSuccessModalVisible,
          forceChangePasswordModalVisible,
          setForceChangePasswordModalVisible,
          forceUsername,
          setForceUsername,
          forcePassword,
          setForcePassword,
          setTab,
          tab,
          setError,
          error,
          searchTerm,
          setSearchTerm,
          holidays,
          metaTags,
          setMetaTags,
        }}
      >
        {props.children}
      </GlobalVariablesContextProvider>
    </>
  )
}
export default GlobalVariablesContextWrapper
